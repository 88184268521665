import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";

export default ({ data, pageContext, location }) => {
  var post;
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language

        post = data.allPagesJson.nodes[0]

  } else {
    post = data.allPagesJson.nodes[0]
  }

  if (post.hasTopSection === true) {
    return (
      <SharedStateProvider>
      <Layout language={language}>
      <SEO title={post.metaTitle} description={post.metaDescription} pathname={location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
          <div className="joshua-tree-content privacy-policy">
            <div className="columns">
              <div className="column">
                <div className="columns top-section text-section white-back is-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                    <div>
                      <h1 style={{marginTop: 0}}>{post.name}</h1>
                      <MarkdownViewer markdown={post.topText} />
                    </div>
                  </div>
                  <div className="column is-2"></div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns body-section text-section">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                      <MarkdownViewer className="privacy-body" markdown={post.body} />
                  </div>
                  <div className="column is-2"></div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </SharedStateProvider>
    )
  } else {
    return (
      <SharedStateProvider>
        <Layout language={language}>
        <SEO title={post.metaTitle} description={post.metaDescription} pathname={location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
          <div className="joshua-tree-content privacy-policy">
            <div className="columns">
              <div className="column">
                <div className="columns top-section white-back">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                    <div>
                      <h1 style={{marginTop: 0}}>{post.name}</h1>
                      <MarkdownViewer className="privacy-body" markdown={post.body} />
                    </div>
                    <div className="column is-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </SharedStateProvider>
    )
  }
}

// allSpanishPagesJson(filter: { title: { eq: $title } }) {
//   edges {
//     node {
//       title
//       name
//       metaTitle
//       metaDescription
//       hasTopSection
//       topText
//       body
//       manual_file
//     }
//   }
// }

export const pageQuery = graphql`
  query($title: String!) {
    allPagesJson(filter: { title: { eq: $title } }) {

        nodes {
          title
          name
          metaTitle
          metaDescription
          hasTopSection
          topText
          body
          manual_file
        }
      
    }
  }
`
